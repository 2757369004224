@import "./scss/material";
@import "./scss/utils";
@import "./scss/responsive";
@import "./scss/variables";
@import "./scss/theme";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
